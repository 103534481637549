import { Fragment, useEffect } from "react";
import { TranscriptSectionIndexDB } from "../../classes/audioDb";
import { Transition, Dialog, DialogPanel, TransitionChild, DialogTitle } from "@headlessui/react";
import classNames from "classnames";
import UploadIcon from "@icons/upload-01.svg?react"
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getDataGridStyles } from '@/theme';
import InfoCircleIcon from "@icons/info-circle.svg?react";
import HelpCircleIcon from '@icons/help-circle.svg?react';
import XIcon from "@icons/x-close.svg?react";
import { Tooltip } from "react-tooltip";
import UploadModalRow from "./UploadModalRow";
import { useDataContextApi } from "@/state/providers/DataProvider";

/*
Notes:
	- Incomplete uploads are stored in indexed db, see AudioDb class
	- AudioDb is somewhat separate from the rest of the app, so state is a bit weird
	- Poll the db for incomplete uploads every 30 seconds

There are two ways to upload incomplete uploads:
	- We will automatically try to upload incomplete only on refresh, when AudioDb is initialized
	- Otherwise the user can open this modal to see and retry incomplete uploads
*/
const UploadModal: React.FC<{
	disabled: boolean;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	showButton?: boolean;
}> = ({ disabled, open, setOpen, showButton = true }) => {

	const { incompleteUploads } = useDataContextApi();

	const columns: GridColDef[] = [
		{
			field: "patientName",
			headerName: "Clips",
			flex: 2,
			editable: false,
			sortable: false,
			disableColumnMenu: true,
			headerAlign: "left",
			align: "left",
			headerClassName: "datagrid-table--header",
			cellClassName: "datagrid-table--column-cell",
			renderCell: (params) => (
				<div className="flex flex-col mv-2">
					<span className="text-sm text-gray-900 font-medium h-[25px] pt-2">
						{params.row.patientName || '-'}
					</span>
					<span className="text-sm text-gray-600 h-[25px]">
						{params.row.fileName || 'file name'}
					</span>
				</div>
			)
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			flex: 1,
			headerAlign: "right",
			align: "right",
			sortable: false,
			disableColumnMenu: true,
			headerClassName: 'datagrid-table--header',
			renderCell: (params) => {
				return (
					<UploadModalRow upload={params.row} />
				)
			}
		}
	]

	return (
		<Fragment>
			{showButton && <button
				id="incomplete-upload-modal"
				onClick={() => setOpen(!open)}
				disabled={disabled}
				className={classNames(
					disabled ? "opacity-50 cursor-not-allowed" : "bg-gray-100 text-gray-900 hover:bg-gray-200",
					"rounded bg-white grow px-4 py-2.5 text-[10px] sm:text-sm font-semibold bg-gray-100 hover:bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
				)}
			>
				<span className="flex flex-row justify-center items-center whitespace-nowrap">
					<UploadIcon className="h-4 w-4 mr-1 text-gray-900" />
					<span className="sm:hidden">Upload</span>
					<span className="hidden sm:inline">Incomplete Uploads</span>

					{incompleteUploads &&
						// Use optional chaining with filter and immediately get the length
						// If incompleteUploads is null, this will short-circuit and return 0
						incompleteUploads.filter((upload) => !upload.isUploading).length > 0 && (
							<span className="ml-1 text-white px-2 py-0 bg-red-500 rounded-full">
								{incompleteUploads.filter((upload) => !upload.isUploading).length}
							</span>
						)}
				</span>
			</button>}

			<Transition show={open} as={Fragment}>
				<Dialog id="incomplete-modal-dialog" as="div" className="relative z-50" onClose={() => setOpen(!open)} static={true}>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all m-4 sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
									<div>
										<div className="text-center">
											<DialogTitle
												as="h1"
												className="text-xl mb-3 font-semibold leading-6 text-gray-900 text-left flex flex-row items-center justify-between relative font-"
											>
												Incomplete Uploads

												<div onClick={() => setOpen(false)}>
													<XIcon className="h-6 w-6 text-gray-500 hover:text-gray-900 cursor-pointer" />
												</div>
											</DialogTitle>
										</div>
									</div>
									<div>
										<div className="mt-5 sm:mt-6">
											<div className="border rounded-lg bg-white shadow-sm">
												<div className="flex pt-3 pb-1 px-5 justify-between items-center mb-2">
													<div className='w-full flex items-center justify-between'>
														<div className='flex gap-2'>
															<h2 className="text-lg font-semibold text-gray-900">Locally Stored Clips</h2>
															<Tooltip
																content="This table shows all audio clips not fully uploaded from the past week. Audio clips that are not uploaded are automatically deleted after 1 week."
																anchorSelect="#incomplete-uploads-tooltip"
																style={{ maxWidth: '300px', zIndex: 9999 }}
															/>
															<InfoCircleIcon id="incomplete-uploads-tooltip" className={`h-[13px] w-[13px] mr-1 mt-1.5 focused `} />
														</div>

														<button
															onClick={() => {
																window.open("https://help.gohappydoc.com/en/articles/8563272-trouble-shooting-common-issues", "_blank", "noopener,noreferrer");
															}}
															className='flex items-center py-1 px-2 border rounded-md bg-white shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-max-700'
														>
															<HelpCircleIcon className={`h-[13px] w-[13px] mr-1`} />
															<span className='text-xs'>Help</span>
														</button>
													</div>
												</div>
												<div className='w-full h-full'>
													<DataGrid
														getRowId={(row: TranscriptSectionIndexDB) => row.transcriptSectionId}
														rows={incompleteUploads}
														columns={columns}
														editMode="row"
														checkboxSelection={false}
														hideFooter={true}
														getRowClassName={() => 'datagrid-table--row'}
														getCellClassName={() => 'datagrid-table--cell'}
														sx={getDataGridStyles(false)}
													/>
												</div>
											</div >
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	);
};

export default UploadModal;
