// external libraries
import { lazy, Suspense, useEffect, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import classNames from "classnames";

// hooks and state
import { useAccountContext } from "@providers/AccountProvider";
import { useAppSelector } from '@common/hooks/useRedux';
import { getSortedDoctors } from '@/state/redux/doctorSlice';
import { useDashboardContext } from "./state/DashboardProvider";

// components
import TableTabs from "@common/components/HDTabs";
import Loading from "./components/common/Loading";

// lazy-loaded components
const InsightsOverview = lazy(() => import("./components/InsightsOverview"));
const SentimentAnalysis = lazy(() => import("./components/SentimentAnalysis"));

// types and utilities
import { Doctor } from "@/common/utils/types";
import { DashboardDoctor } from "./components/common/DoctorMultiSelect";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/*
* Maps a Doctor object to a DashboardDoctor object
* @param {Doctor} doctor - The doctor object
* @returns {DashboardDoctor | undefined}
*/
const mapDoctorToDashboardDoctor = (doctor: Doctor): DashboardDoctor | undefined => {
    if (doctor?.doctor_name && doctor?.id) {
        return {
            id: doctor.id,
            name: doctor.doctor_name,
        };
    } else {
        return undefined;
    }
};

// MUI theme for insights
const insightsTheme = createTheme({
    typography: {
        fontFamily: "Plus Jakarta Sans, Arial, sans-serif",
        button: {
            textTransform: "none",
            fontWeight: 600,
        },
    },
    palette: {
        primary: {
            main: "#1D2939",
        },
        secondary: {
            main: "#344054",
        },
        background: {
            default: "#E5E7EB",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    border: "1px solid #D0D5DD",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    color: "#344054",
                    backgroundColor: "transparent",
                    fontFamily: "'Plus Jakarta Sans', Arial, sans-serif",
                    fontSize: "14px",
                    fontWeight: 600,
                    textTransform: "none",
                    transition: "none",
                    "&:hover": {
                        border: "1px solid #D0D5DD",
                        backgroundColor: "#D0D5DD",
                    },
                    "&.selected": {
                        color: "#1D2939",
                        backgroundColor: "#EAFAFB",
                    },
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
            },
        },
    },
});

// The list of possible tabs in the dashboard
export enum TabIds {
    Overview = "overview",
    SentimentAnalysis = "sentiment-analysis",
}

// render the tab based on the tab id
const renderTab = (tab: string) => {
    switch (tab) {
        case TabIds.SentimentAnalysis:
            return (
                <Suspense fallback={<Loading />}>
                    <SentimentAnalysis />
                </Suspense>
            );
        case TabIds.Overview:
        default:
            return (
                <Suspense fallback={<Loading />}>
                    <InsightsOverview />
                </Suspense>
            )
    }
}

function Dashboard() {

    const { accountData } = useAccountContext();
    const doctors = useAppSelector(getSortedDoctors);
    const { dashboardStateContext } = useDashboardContext();
    const { setDashboardDoctors, tabs, handleTabClick } = dashboardStateContext;

    useEffect(() => {
        if (!doctors) {
            return;
        }

        setDashboardDoctors(doctors.map((doc) => mapDoctorToDashboardDoctor(doc)).filter((doc) => doc !== undefined) as DashboardDoctor[]);
    }, [doctors]);

    const queryClient = useMemo(() => new QueryClient(), []);

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <div className={classNames("pl-8 pt-16 bg-white", tabs.length > 1 ? "" : "pb-4")}>
                    <h1 className="font-semibold text-3xl text-gray-900">
                        {"View key insights" + (accountData?.clinic_name ? ` for ${accountData?.clinic_name}` : "")}
                    </h1>
                    <p className="text-gray-600 text-sm">
                        Access a detailed overview of key metrics and visit patient outcomes.
                    </p>
                    {tabs.length > 1 && (
                        <div className="pt-4">
                            <TableTabs tabs={tabs} changeTab={handleTabClick} />
                        </div>
                    )}
                </div>
                <div className="p-4">
                    <ThemeProvider theme={insightsTheme}>
                        {renderTab(tabs.find(tab => tab.isActive)?.id || TabIds.Overview)}
                    </ThemeProvider>
                </div>
            </QueryClientProvider>
        </div>
    );
}

export default Dashboard;