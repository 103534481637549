import { useSessionContext } from "@supabase/auth-helpers-react";
import { createContext, useRef, useEffect, useContext } from "react";
import {
	BROADCAST_EVENT,
	BroadcastEvents,
	BroadcastTypes,
	Doctor,
	TranscriptSectionStatus,
} from "@common/utils/types";
import {
	incrementRecordingCount,
	removeRecord,
	addDocument,
	removeDocument,
	handlePatientTableBroadcast,
} from "@redux/patientTableRecordsSlice";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { handleActivePatientBroadcast, updateOrAddTranscriptSection } from "@features/patient-view/state/redux/activePatientDataSlice";
import { RealtimeChannel } from "@supabase/supabase-js";
import {
	addOrUpdateTabAndAddDocument,
	addToAllDocuments,
	removeDocumentFromTab,
	removeFromAllDocuments,
	resetProgress,
	setIsGenerating,
	setProgress,
	updateDocumentInTab,
	updateDocumentSectionInTab,

} from "@features/patient-view/state/redux/activeDocumentsSlice";
import { createTabId } from "@/common/utils/helpers";
import * as Sentry from "@sentry/react";
import { addSelectedDocument, removeSelectedDocument } from "../redux/documentSlice";
import { doctorUpdated } from "../redux/doctorSlice";

export const RealTimeContextApi = createContext<{
	selectedDocumentsRef: React.MutableRefObject<string[]>;
	generateOnStopRef: React.MutableRefObject<boolean>;
	selectedDoctorRef: React.MutableRefObject<Doctor | null>;
	emitEvent: (eventType: BroadcastEvents, payload: any) => Promise<void>;
}>({
	selectedDoctorRef: { current: null },
	selectedDocumentsRef: { current: [] },
	generateOnStopRef: { current: false },
	emitEvent: async () => { }
});

export const RealTimeContextProvider = ({ children }: { children: React.ReactNode }) => {
	const { session, supabaseClient: supabase, isLoading } = useSessionContext();

	const { selectedDocuments } = useAppSelector((state) => state.documents);
	const { selectedDoctor } = useAppSelector((state) => state.doctors);

	// refs to store selected documents & whether transcribe call should generate them
	const selectedDocumentsRef = useRef<string[]>([]);
	const generateOnStopRef = useRef<boolean>(false);

	const selectedDoctorRef = useRef<Doctor | null>(selectedDoctor)
	const supaSuccessChannelRef = useRef<RealtimeChannel | null>(null);

	// Update refs when relevant state changes
	useEffect(() => {
		selectedDocumentsRef.current = selectedDocuments;
		selectedDoctorRef.current = selectedDoctor
	}, [selectedDocuments, selectedDoctor]);

	const dispatch = useAppDispatch();

	// emit a generic event on the supa success channel
	const emitEvent = async (eventType: BroadcastEvents, payload: any) => {
		const {
			data: { session },
			error: sessionError,
		} = await supabase.auth.getSession();

		if (sessionError || !session) {
			Sentry.captureException(sessionError);
			return
		}

		if (!session?.user?.id) {
			Sentry.captureMessage("Error in emitEvent in RealtimeProvider.tsx", {
				level: "error",
				extra: { eventType, payload },
			});
			return;
		}

		const channel = supaSuccessChannelRef.current

		if (!channel) {
			Sentry.captureMessage("Emit event - no channel", {
				level: 'error',
				extra: {
					userId: session?.user?.id,
					event: eventType,
					payload: payload
				}
			})
			return;
		}

		try {
			await channel.send({
				type: "broadcast",
				event: "client-update",
				payload: { type: "success", eventType, data: payload },
			});
			return
		} catch (error) {
			Sentry.captureException(error);
			return
		}
	};

	//subscribe to the supabase realtime channel
	useEffect(() => {
		if (!supabase || !session?.user?.id || isLoading) {
			return;
		}

		if (!supaSuccessChannelRef.current) {
			supaSuccessChannelRef.current = supabase.channel(session.user.id as string);

			supaSuccessChannelRef.current
				.on("broadcast", { event: BROADCAST_EVENT }, (message: { payload: { type: any; eventType: any; data: any } }) => {
					const { type, eventType, data } = message?.payload;
					const isSuccess = type === BroadcastTypes.success;

					// Handle events here
					switch (eventType) {
						case BroadcastEvents.transcription:
							handleTranscriptionEvent(isSuccess, data);
							break;
						case BroadcastEvents.document:
							handleDocumentEvent(data);
							break;
						case BroadcastEvents.documentUpdated:
							handleDocumentUpdatedEvent(data);
							break;
						case BroadcastEvents.documentCreated:
							handleDocumentCreatedEvent(data);
							break;
						case BroadcastEvents.documentDeleted:
							handleDocumentDeletedEvent(data);
							break;
						case BroadcastEvents.documentSelection:
							handleDocumentSelectionEvent(data);
							break;
						case BroadcastEvents.patientDeleted:
							handlePatientDeletedEvent(data);
							break;
						case BroadcastEvents.patientEvent:
							handlePatientEvent(data);
							break;
						case BroadcastEvents.emailConfirmationSent:
							handleEmailConfirmationSent(data);
							break;
						case BroadcastEvents.emailConfirmed:
							handleEmailConfirmed(data);
							break;
						default:
							break;
					}
				})
				.subscribe((status) => {
					if (status === "SUBSCRIBED") {
						// yeeterz - we are subscribed
					}
				});
		}


		const handleDisconnect = () => {
			Sentry.captureMessage("Disconnected from Realtime Channel", {
				level: "warning",
				extra: { userId: session?.user?.id, email: session?.user?.email },
			});

			// reconnect to the channel
			if (supaSuccessChannelRef.current) {
				try {
					supaSuccessChannelRef.current.subscribe();
					Sentry.captureMessage("Reconnected to Realtime Channel", {
						level: "info",
						extra: { userId: session?.user?.id, email: session?.user?.email },
					});
				} catch (error) {
					Sentry.captureException(error);
				}
			}
		};

		// listen for connection events
		supabase.getChannels().forEach((channel) => {
			channel
				.on("presence", { event: "leave" }, () => {
					// handle disconnection logic
					handleDisconnect();
				});
		});


		return () => {
			if (supaSuccessChannelRef.current) {
				supabase.removeChannel(supaSuccessChannelRef.current);
				supaSuccessChannelRef.current = null;
			}
		};
	}, [supabase, session?.user?.id, isLoading]);

	// Event handler functions
	const handleTranscriptionEvent = (isSuccess: boolean, data: any) => {
		const { transcriptionId, transcriptSectionId, data: transcript, patientRecordId, status, audioDuration, unprocessed_filename } = data;

		// TODO: just fetch the thing from the db
		if (isSuccess) {
			dispatch(
				updateOrAddTranscriptSection({
					transcriptId: transcriptionId,
					transcriptSection: {
						id: transcriptSectionId,
						data: transcript,
						status: status,
						audio_duration: audioDuration,
						unprocessed_filename: unprocessed_filename,
					},
				})
			);
			dispatch(
				incrementRecordingCount({
					transcriptionId,
					sectionId: transcriptSectionId,
					status: TranscriptSectionStatus.Completed,
				})
			);
		} else {
			dispatch(
				updateOrAddTranscriptSection({
					transcriptId: transcriptionId,
					transcriptSection: {
						id: transcriptSectionId,
						data: "",
						status: TranscriptSectionStatus.FailedErrorProcessing,
					},
				})
			);
			dispatch(
				incrementRecordingCount({
					transcriptionId,
					sectionId: transcriptSectionId,
					status: TranscriptSectionStatus.FailedErrorProcessing,
				})
			);
		}
	};

	const handleDocumentEvent = (data: any) => {
		const { patientRecordId, chatCompletion } = data;

		const tabId = createTabId(chatCompletion.document_type_id, chatCompletion.chat_json?.header);
		if (tabId) {
			dispatch(updateDocumentInTab({ tabId, document: chatCompletion, patientRecordId }));
			dispatch(resetProgress({ tabId, patientRecordId }));
			dispatch(setIsGenerating({ isGenerating: false, patientRecordId }));
		}
	};

	const handleDocumentUpdatedEvent = (data: any) => {
		const {
			patientRecordId,
			chatCompletionId,
			documentTypeId,
			section,
			header,
			progress,
			index,
		} = data;

		const tabId = createTabId(documentTypeId, header);
		if (tabId) {
			dispatch(updateDocumentSectionInTab({ tabId, chatId: chatCompletionId, section, index, patientRecordId }));
			dispatch(setProgress({ tabId, progress, patientRecordId }));
			dispatch(setIsGenerating({ isGenerating: true, patientRecordId }));
		}
	};

	const handleDocumentCreatedEvent = (data: any) => {
		const { patientRecordId, chatCompletion } = data;

		dispatch(addDocument({ id: patientRecordId, chatCompletion }));

		const tabId = createTabId(chatCompletion.document_type_id, chatCompletion.chat_json?.header);
		if (tabId) {
			dispatch(addOrUpdateTabAndAddDocument({ tabId, document: chatCompletion, patientRecordId }));
			dispatch(resetProgress({ tabId, patientRecordId }));
			dispatch(addToAllDocuments({ document: chatCompletion, patientRecordId }));
			dispatch(setIsGenerating({ isGenerating: true, patientRecordId }));
		}
	};

	const handleDocumentDeletedEvent = (data: any) => {
		const { chatMessageId, patientRecordId, documentName } = data;

		dispatch(removeDocument({ id: patientRecordId, documentId: chatMessageId }));

		const tabId = createTabId(chatMessageId, documentName);

		if (tabId) {
			dispatch(removeDocumentFromTab({ tabId, documentId: chatMessageId }));
			dispatch(resetProgress({ tabId, patientRecordId }));
			dispatch(removeFromAllDocuments({ documentId: chatMessageId, patientRecordId }));
		}
	};

	const handleDocumentSelectionEvent = (data: any) => {
		const { documentTypeId, remove, doctorId } = data;

		// only update if same selected doctor
		const isSameDoctor = doctorId === selectedDoctorRef.current?.id;

		// only update the selected documents if the doctor is the same
		if (remove && isSameDoctor) {
			dispatch(removeSelectedDocument(documentTypeId));
		} else if (!remove && isSameDoctor) {
			dispatch(addSelectedDocument(documentTypeId));
		}
	}

	const handlePatientDeletedEvent = (data: any) => {
		const { patientRecordId } = data;
		dispatch(removeRecord({ id: patientRecordId }));
	};

	const handlePatientEvent = (data: any) => {
		const { patientRecordId } = data;

		// update table state
		dispatch(handlePatientTableBroadcast({ patientRecordId }));

		// update active patient record state
		dispatch(handleActivePatientBroadcast({ patientRecordId }));
	}

	const handleEmailConfirmationSent = (data: any) => {
		const { doctorId, sentAt } = data;
		if (!doctorId || !sentAt) return;

		dispatch(
			doctorUpdated({
				id: doctorId,
				confirmation_sent_at: sentAt,
			})
		)
	}

	const handleEmailConfirmed = (data: any) => {
		const { doctorId, confirmedAt } = data;
		if (!doctorId || !confirmedAt) return;

		dispatch(
			doctorUpdated({
				id: doctorId,
				email_confirmed_at: confirmedAt,
			})
		)
	}

	const value = {
		selectedDoctorRef,
		selectedDocumentsRef,
		generateOnStopRef,
		emitEvent
	};

	return <RealTimeContextApi.Provider value={value}>{children}</RealTimeContextApi.Provider>;
};

export const useRealtimeContextApi = () => {
	const context = useContext(RealTimeContextApi);
	if (context === undefined) {
		throw new Error(`DataContextAPi must be used within a DataContextProvider`);
	}
	return context;
};
