import { PopperPlacementType } from "@mui/material";

// this should be used to override the default style for MUI x-charts' built-in tooltip
export const graphTooltipStyle = {
  sx: {
    "& .MuiChartsTooltip-paper": {
      backgroundColor: "black",
      opacity: 0.9,
      "& .MuiChartsTooltip-row:first-of-type": {
        borderTop: "2px solid rgba(100, 100, 100, 0.3)"
      },
      "& .MuiChartsTooltip-mark": {
        borderRadius: "20%",
        border: "none",
        width: "12px",
        height: "12px",
      },
      "& .MuiChartsTooltip-valueCell": {
        paddingLeft: "10px",
      },
      "& .MuiTypography-root": {
        color: "white",
        fontSize: "12px",
        fontFamily: "Plus Jakarta Sans",
      },
    },
  },
  placement: "right-start" as PopperPlacementType,
};