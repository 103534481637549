// common components and types
import { DashboardDoctor } from "@features/dashboard/components/common/DoctorMultiSelect";
import { ErrorBoundary } from "@sentry/react";

// metric components
import { hydrateRecordsGenerated, RecordsGenerated } from "@features/dashboard/components/metrics/RecordsGenerated";
import { hydrateTimeSaved, TimeSaved } from "@features/dashboard/components/metrics/TimeSaved";
import { hydrateSentimentAnalysis, SentimentAnalysis } from "./SentimentAnalysis";
import { hydrateVisitsSeen, VisitsSeen } from "@features/dashboard/components/metrics/VisitsSeen";
import { hydrateTimeSpentInVisits, TimeSpentInVisits } from "./TimeSpentInVisits";
import { AverageClientTransaction, hydrateAverageClientTransaction } from "./AverageClientTranscation";
import { useDashboardContext } from "../../state/DashboardProvider";

export type InsightsMetric = {
    metricKey: MetricKeys;
    name: string;
    isPremium: boolean;
}

// MetricComponentProps is the interface for the props of the MetricComponent component.
type MetricComponentProps = {
    metric: InsightsMetric;
};

// MetricKeys is an enum that contains the keys for the metrics that can be rendered on the dashboard.
export enum MetricKeys {
    RecordsGenerated = "RecordsGenerated",
    TimeSaved = "TimeSaved",
    SentimentAnalysis = "SentimentAnalysis",
    VisitsSeen = "VisitsSeen",
    TimeSpentInVisits = "TimeSpentInVisits",
    TimeSpentInVisitsUncategorized = "TimeSpentInVisitsUncategorized",
    AverageClientTransaction = "AverageClientTransaction",
}

// MetricComponentsOrder is an array that contains the order of the metric components on the dashboard.
// If itemX appears after itemY in the array, then itemX will be rendered below itemY on the dashboard (within a column)
// (note, actual definition of left and right is in assignMetricKeyToColumn)
export const MetricComponentsOrder = [
    // left
    MetricKeys.TimeSaved,
    MetricKeys.TimeSpentInVisits,
    MetricKeys.TimeSpentInVisitsUncategorized,
    MetricKeys.SentimentAnalysis,
    // right
    MetricKeys.RecordsGenerated,
    MetricKeys.VisitsSeen,
    MetricKeys.AverageClientTransaction,
]

// MetricHydrator is an interface for the hydrator function that fetches the data for the metric
interface MetricHydrator {
    (bearerToken: string, dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string): Promise<unknown>;
}

// getMetricKeyHydrator is a helper function that returns the hydrator function for the metric key provided.
export function getMetricKeyHydrator(key: MetricKeys): MetricHydrator {
    switch (key) {
        case MetricKeys.RecordsGenerated:
            return hydrateRecordsGenerated;
        case MetricKeys.TimeSaved:
            return hydrateTimeSaved;
        case MetricKeys.SentimentAnalysis:
            return hydrateSentimentAnalysis;
        case MetricKeys.VisitsSeen:
            return hydrateVisitsSeen;
        case MetricKeys.TimeSpentInVisits:
        case MetricKeys.TimeSpentInVisitsUncategorized:
            return hydrateTimeSpentInVisits;
        case MetricKeys.AverageClientTransaction:
            return hydrateAverageClientTransaction;
    }
}

/*
* renderMetricFromKey is a helper function that will render a metric component
* based on the key provided.
*/
function renderMetricFromKey(key: string) {
    switch (key) {
        case MetricKeys.RecordsGenerated:
            return <RecordsGenerated />
        case MetricKeys.TimeSaved:
            return <TimeSaved />
        case MetricKeys.SentimentAnalysis:
            return <SentimentAnalysis />
        case MetricKeys.VisitsSeen:
            return <VisitsSeen />
        case MetricKeys.TimeSpentInVisits:
            return <TimeSpentInVisits showCategories={true} />
        case MetricKeys.TimeSpentInVisitsUncategorized:
            return <TimeSpentInVisits showCategories={false} />
        case MetricKeys.AverageClientTransaction:
            return <AverageClientTransaction />
    }
}

/*
* assignMetricKeyToColumn is a helper function that assigns a metric key to a column
* based on the key provided.
*/
export function assignMetricKeyToColumn(metricKey: MetricKeys): "left" | "right" {
    switch (metricKey) {
        case MetricKeys.RecordsGenerated:
            return "right";
        case MetricKeys.SentimentAnalysis:
            return "left";
        case MetricKeys.TimeSaved:
            return "left";
        case MetricKeys.VisitsSeen:
            return "right";
        case MetricKeys.TimeSpentInVisits:
        case MetricKeys.TimeSpentInVisitsUncategorized:
            return "left";
        case MetricKeys.AverageClientTransaction:
            return "right";
        default:
            return "left";
    }
}

function getInsightNameFromKey(key: string) {
    switch (key) {
        case MetricKeys.RecordsGenerated:
            return "Records Generated";
        case MetricKeys.TimeSaved:
            return "Time Saved";
        case MetricKeys.SentimentAnalysis:
            return "Sentiment Analysis";
        case MetricKeys.VisitsSeen:
            return "Visits Seen";
        case MetricKeys.TimeSpentInVisits:
        case MetricKeys.TimeSpentInVisitsUncategorized:
            return "Time Spent in Visits";
        case MetricKeys.AverageClientTransaction:
            return "Average Client Transaction";
        default:
            return "";
    }
}

/*
* errorBoundaryMessage is a helper function that returns an error message
* based on the title provided.
*/
const errorBoundaryMessage = (title: string) => {
    if (title) {
        return (
            <p className="px-4 py-2 text-red-900 text-medium">There was an error loading data about {title}.</p>
        )
    } else {
        return (
            <p className="px-4 py-2 text-red-900 text-medium">There was an error loading this data.</p>
        )
    }
}

/*
* MetricComponent is the generic component for rendering a metric on the dashboard page.
* This component will render different metrics based on the key provided.
*/
export const MetricComponent = (props: MetricComponentProps) => {

    const { dashboardStateContext } = useDashboardContext();
    const { hasPremiumAccess } = dashboardStateContext;
    const { metric } = props;

    // if the metric is premium and the user does not have premium access, do not render the metric
    if (metric.isPremium && !hasPremiumAccess) {
        return null;
    }

    return (
        <div className="pb-4">
            <ErrorBoundary fallback={<div className="bg-white rounded-md px-4 py-2 text-red-900 text-medium">{errorBoundaryMessage(getInsightNameFromKey(metric.metricKey))}</div>}>
                {renderMetricFromKey(metric.metricKey)}
            </ErrorBoundary>
        </div >
    )
}