// third party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import { BarChart } from '@mui/x-charts';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";
import { graphTooltipStyle } from "@features/dashboard/components/common/styles";

// dashboard components
import { getStartRangeTimestamp, getEndRangeTimestamp } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, getLabelFromDoctorId, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";
import { dashboardColorToHex } from "@features/dashboard/components/common/color";

// API and queries
import { makeServiceInsightsGraphqlRequest, TIME_SPENT_IN_VISITS_QUERY } from "../dashboardAPI";

type TimeSpentInVisitsData = {
    totalTimeSpentInVisits: {
        visitType: string,
        timeSpent: number
    }[],
    breakdownByDoctor: {
        doctorId: string,
        timeSpentInVisits: {
            visitType: string,
            timeSpent: number
        }[],
    }[],
}

type BarChartData = {
    xAxis: string[]; // Doctor names or IDs
    series: {
        label: string; // Visit type (e.g., SICKNESS, WELLNESS)
        data: number[]; // Time spent for each doctor
    }[];
};

type TimeSpentInVisistsDataset = {
    [key: string]: TimeSpentInVisitsData
}

export async function hydrateTimeSpentInVisits(bearerToken: string, dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string): Promise<TimeSpentInVisitsData> {

    // set variables for the query
    const variables = {
        startRangeTimestamp,
        endRangeTimestamp,
        selectedDoctorIDs: [...dashboardDoctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID], // retrieve data for all doctors in the dashboard, and unassigned
    };

    try {
        // make graphql request
        const { data, error } = await makeServiceInsightsGraphqlRequest(TIME_SPENT_IN_VISITS_QUERY, variables, bearerToken);

        // handle error
        if (error) {
            throw error
        }

        // return data
        return data?.timeSpentInVisits

    } catch (error) {
        Sentry.captureException(`Error fetching time spent in visits: ${error}`);
        return {
            totalTimeSpentInVisits: [],
            breakdownByDoctor: []
        }
    }
}

enum VisitTypes {
    SICKNESS = "SICKNESS",
    WELLNESS = "WELLNESS",
    OVERALL = "OVERALL",
}

const displayVisitType = (visitType: string) => {
    switch (visitType) {
        case VisitTypes.SICKNESS:
            return "Sick";
        case VisitTypes.WELLNESS:
            return "Well";
        case VisitTypes.OVERALL:
            return "Hours in Visits";
        default:
            return visitType;
    }
}

const getVisitTypeColor = (visitType: string) => {
    switch (visitType) {
        case VisitTypes.OVERALL:
        case VisitTypes.SICKNESS:
            return dashboardColorToHex("max-700")
        case VisitTypes.WELLNESS:
        default:
            return dashboardColorToHex("max-500")
    }
}

const secsToHrs = (secs: number) => Math.round((secs / (60 * 60)) * 10) / 10;

export type TimeSpentInVisitsProps = {
    showCategories?: boolean;
}

export const TimeSpentInVisits = ({ showCategories }: TimeSpentInVisitsProps) => {

    const metricKeyToUse = showCategories ? MetricKeys.TimeSpentInVisits : MetricKeys.TimeSpentInVisitsUncategorized;
    const visitTypesToUse = showCategories ? [VisitTypes.SICKNESS, VisitTypes.WELLNESS] : [VisitTypes.OVERALL] // if show categories is false, only show overall time spent in visits
    const includeLegend = showCategories ? true : false;

    // CONTEXT AND DATA PROVIDERS
    // provided data
    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const metricData = dashboardMetricsContext?.metricData[metricKeyToUse]?.data as TimeSpentInVisistsDataset;
    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors } = dashboardStateContext;

    // component data and loading state
    const [componentData, setComponentData] = useState<TimeSpentInVisitsData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    // update componentData when metricData or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[getDataKey(dashboardDoctors, getStartRangeTimestamp(selectedDateRangeButtonValue), getEndRangeTimestamp(selectedDateRangeButtonValue))];

        setComponentData(data);
        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // SORTING DOCTOR IDS
    const [sortedSelectedDoctorIds, setSortedSelectedDoctorIds] = useState<string[]>([]);

    // update order of selectedDoctorIds when selectedDoctorIds change
    useEffect(() => {
        if (!componentData) {
            return;
        }

        // include unassigned doctor if all doctors are selected
        let baseIds = selectedDoctorIds;
        if (selectedDoctorIds.length === dashboardDoctors.length) {
            baseIds = [...baseIds, UNASSIGNED_DOCTOR_ID]
        }

        // filter out doctors with no time spent in visits 
        baseIds = baseIds.filter((doctorId) => {
            // get the visit categories for this doctor
            const doctorData = componentData.breakdownByDoctor.find((doc) => doc.doctorId === doctorId);
            // filter out null and undefined values
            const visitCategories = doctorData?.timeSpentInVisits.filter(x => x !== null && x !== undefined) ?? [];
            // get the total time spent in visits
            const total = visitCategories.reduce((acc, curr) => acc + curr.timeSpent, 0);

            // return true if the total time spent in visit when converted to rounded hours is greater than 0
            return secsToHrs(total) > 0;
        });

        // set the sorted selected doctor ids
        setSortedSelectedDoctorIds(baseIds);
    }, [selectedDoctorIds, componentData]);


    // DOCTOR SELECTION
    // state to track if all doctors are selected
    const [allDocsSelected, setAllDocsSelected] = useState<boolean>(false);

    // update allDocsSelected when selectedDoctorIds change
    useEffect(() => {
        setAllDocsSelected(selectedDoctorIds.length === dashboardDoctors.length);
    }, [selectedDoctorIds, dashboardDoctors]);

    // BAR CHART DATA
    // state to track line chart data
    const [barChartData, setBarChartData] = useState<BarChartData>({
        xAxis: [],
        series: [],
    });

    // update barChartData when componentData changes
    useEffect(() => {
        if (!componentData) return;

        // doctor names 
        const xAxis: string[] = [];

        // map for series data
        const seriesMap: { [visitType: string]: number[] } = {};

        for (const doctorId of sortedSelectedDoctorIds) {
            // add doctor to xAxis
            const doctorName = getLabelFromDoctorId(doctorId, dashboardDoctors);
            xAxis.push(doctorName);

            // initialize all visit types for the current doctor
            const doctorVisits = componentData.breakdownByDoctor.find(
                (doc) => doc.doctorId === doctorId
            )?.timeSpentInVisits || [];

            for (const visitType of visitTypesToUse) {
                // ensure visit type is in seriesMap
                if (!seriesMap[visitType]) {
                    seriesMap[visitType] = [];
                }

                // add time spent or 0 if not present for this doctor
                const timeSpent =
                    doctorVisits.find((visit) => visit.visitType === visitType)?.timeSpent || 0;
                seriesMap[visitType].push(secsToHrs(timeSpent));
            }
        }

        // convert to the BarChart format
        const series = Object.entries(seriesMap).map(([visitType, data]) => ({
            label: displayVisitType(visitType),
            data,
            color: getVisitTypeColor(visitType),
        }));

        // update barChartData
        setBarChartData({ xAxis, series });

    }, [selectedDateRangeButtonValue, componentData, sortedSelectedDoctorIds, dashboardDoctors]);


    // TOTAL TIME SPENT IN VISITS
    // state for total time spent in visits
    const [totalTimeSpentInVisits, setTotalTimeSpentInVisits] = useState<number>(0);

    // update totalTimeSpentInVisits when componentData changes
    useEffect(() => {
        if (!componentData) return;

        // calculate the total time spent in visits
        const total = componentData?.totalTimeSpentInVisits.reduce((acc, curr) => acc + curr.timeSpent, 0);
        setTotalTimeSpentInVisits(total);
    }, [componentData]);

    return (
        <div className="w-full h-auto min-h-[395px] bg-white rounded-md border border-gray-300">
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[410px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="px-4">
                    {/* HEADER */}
                    <div className="flex items-center py-4 justify-between">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Time Spent in Visits</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-time-spent-in-visits"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-time-spent-in-visits']"
                                    content={
                                        showCategories ?
                                            "Hours recorded in HappyDoc categorized as sick visits or well visits." :
                                            "Hours recorded in HappyDoc."
                                    }
                                    place="top"
                                />
                            </div>
                        </div>
                        <StatDisplay
                            value={`${secsToHrs(totalTimeSpentInVisits)} hrs`}
                            label={allDocsSelected ? "TOTAL" : "PRACTICE TOTAL"}
                            backgroundColor="bg-max-50"
                            width="w-auto"
                            labelAlignment={LabelAlignment.Row}
                        />
                    </div>
                    <Divider customClassNames="rounded-lg" color="gray-100" />

                    {/* BAR CHART */}
                    <div className="w-full h-[300px]">
                        <BarChart
                            xAxis={[{
                                scaleType: 'band',
                                data: barChartData.xAxis,
                                disableTicks: true,
                                disableLine: true,
                            }]}
                            yAxis={[{
                                disableTicks: true,
                                disableLine: true,
                            }]}
                            // custom tooltip for when there is no data
                            tooltip={barChartData.series.length === 0 ? {
                                trigger: 'axis',
                                axisContent: (props) => {
                                    return (
                                        <div className="bg-black bg-opacity-90 text-white p-2 rounded-md text-center font-sans text-xs">
                                            <p>Not enough data for the selected time period.</p>
                                        </div>
                                    )
                                }
                            } : undefined}
                            series={barChartData.series}
                            margin={{ top: 20, right: 20, bottom: includeLegend ? 76 : 24, left: 40 }}
                            slotProps={{
                                legend: {
                                    position: { horizontal: 'middle', vertical: 'bottom' },
                                    labelStyle: {
                                        fill: '#475467',
                                        fontSize: '10px',
                                        fontFamily: 'Plus Jakarta Sans',
                                    },
                                    hidden: !includeLegend,
                                },
                                popper: graphTooltipStyle,
                            }}
                            // unfortunately, the theme provider library for MUI does not support x-charts
                            // todo: extend the ThemeProvider interface to support x-charts
                            sx={{
                                //change left yAxis label styles
                                "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                    fill: "#475467"
                                },
                                // change bottom label styles
                                "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                    fill: "#475467"
                                },
                            }}
                            grid={{ horizontal: true }}
                            borderRadius={8}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
