// external dependencies
import React, { createContext, useContext, useState, useCallback, useMemo, useRef } from "react";
import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";

// internal data providers, hooks, and state
import useAudioRecorder, { recorderControls, isRecordingError } from "@common/hooks/useAudioRecorder";
import useFileUpload from "@common/hooks/useFileUpload";
import usePatientState from "@/features/visits/list-view/state/usePatientState";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";

// internal components and types
import { FeatureFlags, PatientRecord, RecordingState } from "@common/utils/types";
import { toast } from "react-toastify";
import { fetchPatientRecord } from "@/common/lib/supabaseClient";
import { uploadChunk } from "@/common/lib/api";
import { useDataContextApi } from "./DataProvider";
import { useAppSelector } from "@/common/hooks/useRedux";
import { useAccountContext } from "./AccountProvider";

/**
 * Context and Types
**/
// recording widget states
export enum RecordingWidgetState {
	WAITING,
	RECORDING,
	PAUSED,
	UPLOADING,
	SAVED,
	LOADING
}

export enum RecordingWidgetUploadState {
	UPLOADING,
	SUCCESS,
	WARNING,
	ERROR,
}

interface HandleStartRecordingParams {
	patientRecordId?: string | null;
	patientName?: string | null;
}

// controls recorder hook
export type recordingWidgetControls = {
	recordingWidgetState: RecordingWidgetState,
	setRecordingWidgetState: (state: RecordingWidgetState) => void,
	handleStartRecording: (params: HandleStartRecordingParams) => void,
	handleFinishRecording: () => void,
	renderWidget: boolean,
	setRenderWidget: (render: boolean) => void,
}

// controls patient data
export type recordingPatientControls = {
	patientName: string,
	setPatientName: (name: string) => void,
	patientRecordId: string,
	setPatientRecordId: (id: string) => void,
}

// controls file upload
export type recordingFileUploadControls = {
	uploadProgress: number,
	isUploading: boolean,
	uploadMessage: { message: string, type: "info" | "error" | "warning" } | null
}

// controls document selection
export type recordingDocumentSelectControls = {
	setGenerateOnStop: (generate: boolean) => void,
}

// recording provider context
interface AudioRecorderContextValue {
	recorderControls: recorderControls;
	recordingWidgetControls: recordingWidgetControls;
	recordingPatientControls: recordingPatientControls;
	recordingFileUploadControls: recordingFileUploadControls;
	recordingDocumentSelectControls: recordingDocumentSelectControls;
}

const AudioRecorderContext = createContext<AudioRecorderContextValue | undefined>(undefined);

export function useAudioRecordingProvider(): AudioRecorderContextValue {
	const context = useContext(AudioRecorderContext);
	if (!context) {
		throw new Error("useAudioRecorderContext must be used within a AudioRecorderProvider");
	}
	return context;
}

export function AudioRecorderProvider({ children }: { children: React.ReactNode }) {
	/**
	 * recording provider state
	**/
	const [recordingWidgetState, setRecordingWidgetState] = useState<RecordingWidgetState>(RecordingWidgetState.WAITING);
	const [renderWidget, setRenderWidget] = useState<boolean>(false);
	const [patientRecordId, setPatientRecordId] = useState<string>("");

	/**
	 * Providers and Hooks
	**/
	// AUDIO RECORDER
	const {
		startRecording,
		stopRecording,
		togglePauseResume,
		recordingState,
		setRecordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		resetRecorder,
		recordingMessage,
		chunkBuffer,
		blobIndexRef,
	} = useAudioRecorder();

	// PATIENTS
	const {
		patientName,
		setPatientName,
		clearFields,
		handleInsertPatientRecord,
	} = usePatientState();

	// FILE UPLOAD
	// file upload provider
	const {
		uploadProgress,
		isUploading,
		uploadMessage,
		uploadAudio,
	} = useFileUpload();

	// DATA PROVIDER
	const { selectedDate } = useDataContextApi();

	// ACCOUNT
	const { containsFeatureFlag } = useAccountContext();

	// REDUX
	const { selectedDoctor } = useAppSelector((state) => state.doctors);

	// REFS
	const { generateOnStopRef, selectedDocumentsRef } = useRealtimeContextApi();

	const isUploadingMissingChunks = useRef(false);

	const isRecording = useMemo(() => {
		return recordingState === RecordingState.recording || recordingState === RecordingState.paused;
	}, [recordingState]);

	// handle setting generate on stop
	const setGenerateOnStop = useCallback((generate: boolean) => {
		generateOnStopRef.current = generate;
	}, [generateOnStopRef]);

	/**
	 * recording provider handlers
	**/
	// handle recording complete
	const onAudioDataAvailableChunk = async (
		audioFile: File,
		patientRecord: PatientRecord,
		transcriptSectionId: string,
		chunkIndex: number,
		end: boolean = false
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}

			// look for missing chunks
			// we use the ref to prevent multiple uploads at the same time, which can happen with 3g and lots of chunks
			// i.e. we only get through half the chunks before the next one starts and then we have two uploads going at once
			if (!isUploadingMissingChunks.current) {
				try {
					// lock the ref
					isUploadingMissingChunks.current = true;

					// grab the missing chunks
					const missingChunks = chunkBuffer.current.filter((chunk) => !chunk.success && !chunk.uploading && chunk.chunkIndex < chunkIndex);

					// sort chunks by index (just in case)
					missingChunks.sort((a, b) => a.chunkIndex - b.chunkIndex);

					// upload em
					for (const chunk of missingChunks) {
						try {
							chunkBuffer.current[chunk.chunkIndex].uploading = true;
							const resp = await uploadChunk(
								chunk.data,
								patientRecord.transcriptions!.id!,
								transcriptSectionId,
								patientRecord.id,
								chunk.chunkIndex,
								false,
							)
							chunkBuffer.current[chunk.chunkIndex].uploading = false;

							if (resp.error) {
								Sentry.addBreadcrumb({ message: 'error uploading missing chunk!', data: { chunkIndex: chunk.chunkIndex, error: resp.error } });
								continue;
							}

							// update the chunk buffer success
							chunkBuffer.current[chunk.chunkIndex].success = true;
						} finally {
							// update the chunk buffer uploading state
							chunkBuffer.current[chunk.chunkIndex].uploading = false;
						}
					};
				} finally {
					// unlock the ref
					isUploadingMissingChunks.current = false;
				}
			}

			const currentIndex = chunkIndex;
			let docIds: string[] = [];

			// if this is the last chunk, use the selected documents
			if (generateOnStopRef.current && end) {
				docIds = selectedDocumentsRef.current;
			}

			try {
				chunkBuffer.current[currentIndex].uploading = true;
				const uploadResponse = await uploadChunk(
					audioFile,
					patientRecord.transcriptions.id,
					transcriptSectionId,
					patientRecord.id,
					currentIndex,
					end,
					docIds,
				)

				// update transcript section for table
				if (uploadResponse?.error) {
					throw new Error(uploadResponse?.error);
				}
			} finally {
				chunkBuffer.current[currentIndex].uploading = false;
			}

			// update the chunk buffer success
			chunkBuffer.current[currentIndex].success = true;
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	// handle recording complete
	const onAudioDataAvailableOld = async (
		audioFile: File,
		patientRecord: PatientRecord,
		transcriptSectionId: string,
		chunkIndex: number,
		end: boolean = false
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}

			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord,
			)

			// update transcript section for table
			if (uploadResponse?.err) {
				throw new Error(uploadResponse?.err);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	// handle start recording
	const handleStartRecording = useCallback(async (
		{ patientRecordId, patientName }: HandleStartRecordingParams
	) => {
		try {
			// check for recording errors
			if (isRecordingError(recordingState)) {
				throw new Error("Error starting recording");
			}

			setRecordingWidgetState(RecordingWidgetState.LOADING);

			let patientRecord: PatientRecord | null = null;

			if (patientName) {
				setPatientName(patientName);
			}

			if (!patientRecordId) {
				// insert patient record
				patientRecord = await handleInsertPatientRecord(true, patientName || undefined);

				if (!patientRecord || !patientRecord.id || !patientRecord.transcriptions?.id) {
					throw new Error("Error starting recording, failed to create patient record");
				}

				Sentry.addBreadcrumb({
					category: "recording",
					message: "Patient record created",
					level: "info",
					data: { patientName, providedPatientRecordId: patientRecordId, createdPatientRecordId: patientRecord.id, createdTranscriptionId: patientRecord?.transcriptions?.id, selectedDate, selectedDoctor }
				});
			} else {
				// fetch the patient record
				const { data, error } = await fetchPatientRecord(patientRecordId);

				if (error || !data) {
					throw new Error("Error starting recording, failed to fetch patient record");
				}

				patientRecord = data;
			}

			setPatientRecordId(patientRecord.id);

			const transcriptSectionId = uuid();

			// FEATURE FLAG CHUNK UPLOAD
			const onDataAvailable = containsFeatureFlag(FeatureFlags.CHUNK_UPLOAD) ? onAudioDataAvailableChunk : onAudioDataAvailableOld;

			// start recording with audio recorder hook
			await startRecording(
				patientRecord,
				transcriptSectionId,
				(audioFile: File, patientRecord: PatientRecord, transcriptSectionId: string, chunkIndex: number, end: boolean) =>
					onDataAvailable(audioFile, patientRecord, transcriptSectionId, chunkIndex, end),
			);

			setRecordingWidgetState(RecordingWidgetState.RECORDING);

			Sentry.addBreadcrumb({
				category: "recording",
				message: "Recording started",
				level: "info",
				data: { patientName, patientRecordId, selectedDate, selectedDoctor, transcriptSectionId },
			});

		} catch (error: any) {
			Sentry.captureException(error);
			// set an error message
			recordingMessage.current = { message: error?.message ? error?.message : "An unexpected error occurred.", type: "error" };
			// set the recording widget state to waiting (reset the display)
			setRecordingWidgetState(RecordingWidgetState.WAITING);
			// reset the recorder after 3 seconds. Making the message disappear
			setTimeout(() => {
				resetRecorder();
			}, 3000);
		}
	}, [recordingState, selectedDate, selectedDoctor, resetRecorder, handleInsertPatientRecord, fetchPatientRecord, startRecording, onAudioDataAvailableChunk, onAudioDataAvailableOld, setRecordingWidgetState, setPatientRecordId, setPatientName]);

	// handle finish recording
	const handleFinishRecording = async () => {
		stopRecording();

		clearFields();
		setPatientRecordId("");

		Sentry.addBreadcrumb({
			category: "recording",
			message: "Recording stopped",
			level: "info",
			data: { patientRecordId, selectedDate, selectedDoctor },
		});
	}

	// safe toggle pause resume
	const safeTogglePauseResume = useCallback(() => {
		// try to toggle pause/resume
		try {
			togglePauseResume();

			setRecordingWidgetState(
				recordingWidgetState === RecordingWidgetState.PAUSED
					? RecordingWidgetState.RECORDING
					: RecordingWidgetState.PAUSED
			);

			Sentry.addBreadcrumb({
				category: "recording",
				message: "Recording paused/resumed",
				level: "info",
				data: { patientRecordId, selectedDate, selectedDoctor, recordingWidgetState },
			});

		} catch (error) {
			// if an error has occurred, handle it by stopping the recording

			// log the error to sentry
			Sentry.captureException(error);

			// set recording widget state to uploading
			setRecordingWidgetState(RecordingWidgetState.UPLOADING);

			// end the recording
			handleFinishRecording();

			// notify the user of the error
			toast.error("An unexpected error occurred while pausing the recording");
		}

	}, [recordingState, resetRecorder, togglePauseResume]);

	/**
	 * recording provider controls
	**/
	// control the recording hook
	const recorderControlsValue: recorderControls = {
		startRecording,
		stopRecording,
		togglePauseResume: safeTogglePauseResume,
		isRecording,
		recordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		resetRecorder,
		setRecordingState,
		recordingMessage,
		chunkBuffer,
		blobIndexRef,
	};

	// control the recording widget
	const recordingWidgetControlsValue: recordingWidgetControls = {
		recordingWidgetState,
		setRecordingWidgetState,
		handleStartRecording,
		handleFinishRecording,
		renderWidget,
		setRenderWidget,
	}

	// control the patient data
	const recordingPatientControlsValue: recordingPatientControls = {
		patientName,
		setPatientName,
		patientRecordId,
		setPatientRecordId
	}

	// control the file upload
	const recordingFileUploadControlsValue: recordingFileUploadControls = {
		uploadProgress,
		isUploading,
		uploadMessage,
	}

	// control the document selection
	const recordingDocumentSelectControlsValue: recordingDocumentSelectControls = {
		setGenerateOnStop: setGenerateOnStop,
	}

	// render the RecordingWidget with audio recorder provider as context
	// this ensures there is only one recording widget per audio recording provider
	// (and as there is only one instance of audio recording provider, there is only one recording widget)
	return (
		<AudioRecorderContext.Provider value={{
			recorderControls: recorderControlsValue,
			recordingWidgetControls: recordingWidgetControlsValue,
			recordingPatientControls: recordingPatientControlsValue,
			recordingFileUploadControls: recordingFileUploadControlsValue,
			recordingDocumentSelectControls: recordingDocumentSelectControlsValue,
		}}>
			{children}
		</AudioRecorderContext.Provider>
	);
}
