// third-party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// dashboard-specific components and utilities
import { getStartRangeTimestamp, getEndRangeTimestamp } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";

// API and queries
import { makeServiceInsightsGraphqlRequest, SENTIMENT_ANALYSIS_QUERY } from "../dashboardAPI";
import { BarChart, BarSeriesType, ChartsAxisContentProps } from "@mui/x-charts";
import { buildSentimentCategoryAggregates, capitalizeFirstLetter } from "@/common/utils/helpers";
import { TabIds } from "../../Dashboard";

export interface VisitSentimentAnalysis {
    id: string;
    doctorId: string;
    ratings: {
        empathy: number;
        patience: number;
        clarity: number;
        efficiency: number;
        professional: number;
        confidence: number;
    };
}

export interface SentimentSummaryData {
    doctorId: string;
    summary: {
        empathy: string;
        patience: string;
        clarity: string;
        efficiency: string;
        professional: string;
        confidence: string;
    };
}


// each category will transform into this shape:
export interface CategoryAggregate {
    category: string;        // e.g. "Empathy"
    summary: string;
    oneCount: number;        // number of visits with a rating of 1
    twoCount: number;        // number of visits with a rating of 2
    threeCount: number;      // number of visits with a rating of 3
    fourCount: number;       // number of visits with a rating of 4
    fiveCount: number;       // number of visits with a rating of 5
    average: number;         // average rating across all visits
}

export const EMPTY_SUMMARY_STRING = "-";

// the data shape for the sentiment analysis
type SentimentAnalysisData = {
    visitSentiment: VisitSentimentAnalysis[];
    sentimentCategories: string[];
    summaries: SentimentSummaryData[];
}

// the data shape for the sentiment analysis caching object
type SentimentAnalysisDataset = {
    [key: string]: SentimentAnalysisData
}

// the range of sentiment values
enum SentimentRange {
    OneCount = "oneCount",
    TwoCount = "twoCount",
    ThreeCount = "threeCount",
    FourCount = "fourCount",
    FiveCount = "fiveCount",
}

interface LegendData {
    color: string;
    label: string;
}

interface LegendItemProps {
    color: string;
    label: string;
}

/**
 * Fetches and hydrates sentiment analysis data for the given doctors within the specified time range.
 *
 * @param {string} bearerToken - The authentication token for the request.
 * @param {DashboardDoctor[]} dashboardDoctors - The list of doctors to retrieve sentiment analysis data for.
 * @param {string} startRangeTimestamp - The start timestamp for the data range.
 * @param {string} endRangeTimestamp - The end timestamp for the data range.
 * @returns {Promise<SentimentAnalysisData>} - A promise that resolves to the sentiment analysis data.
 *
 * @throws Will throw an error if the GraphQL request fails.
 */
export async function hydrateSentimentAnalysis(bearerToken: string, dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string): Promise<SentimentAnalysisData> {

    // set variables for the query
    const variables = {
        startRangeTimestamp,
        endRangeTimestamp,
        selectedDoctorIDs: [...dashboardDoctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID], // retrieve data for all doctors in the dashboard, and unassigned
    };

    try {
        // make graphql request
        const { data, error } = await makeServiceInsightsGraphqlRequest(SENTIMENT_ANALYSIS_QUERY, variables, bearerToken);

        // handle error
        if (error) {
            throw error
        }

        // return data
        return data?.sentimentAnalysis

    } catch (error) {
        Sentry.captureException(`Error fetching sentiment analysis data: ${error}`);
        return {
            visitSentiment: [],
            sentimentCategories: [],
            summaries: [],
        }
    }
}

export const SentimentAnalysis = () => {
    // provider data
    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors, handleTabClick } = dashboardStateContext;
    const metricData = dashboardMetricsContext?.metricData[MetricKeys.SentimentAnalysis]?.data as SentimentAnalysisDataset

    const [componentData, setComponentData] = useState<SentimentAnalysisData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    const [chartData, setChartData] = useState<CategoryAggregate[]>([]);
    const [barSeries, setBarSeries] = useState<BarSeriesType[]>([]);
    const [sentimentCategories, setSentimentCategories] = useState<string[]>([]);
    const [selectedAverage, setSelectedAverage] = useState<string>("0");
    const [practiceAverage, setPracticeAverage] = useState<string>("0");

    // current behavior is that when all doctors are selected, the selectedDoctorIds array will contain all doctor ids, plus the unassigned doctor id
    // and if none are selected, the selectedDoctorIds array will contain only the unassigned doctor id
    const showSelectedSentimentAverage = !selectedDoctorIds.includes(UNASSIGNED_DOCTOR_ID);

    // get the average sentiment score across all categories
    const getSentimentAverage = (data: CategoryAggregate[]) => {
        // if no data, return 0.0
        if (!data.length) return "0";

        // calculate the average
        let avg = data.reduce((sum, category) => sum + category.average, 0) / data.length

        // round to one decimal place
        avg = Math.round(avg * 10) / 10;

        return avg.toString();
    }

    // get the color for the sentiment range
    const getSentimentRangeColor = (range: SentimentRange) => {
        switch (range) {
            case SentimentRange.OneCount:
                return '#CF2150';
            case SentimentRange.TwoCount:
                return '#FE97A8';
            case SentimentRange.ThreeCount:
                return '#D0D5DD';
            case SentimentRange.FourCount:
                return '#98EAEE';
            case SentimentRange.FiveCount:
                return '#26B5BB';
            default:
                return '#000000';
        }
    }

    // update componentData when metricData, or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[
            getDataKey(dashboardDoctors,
                getStartRangeTimestamp(selectedDateRangeButtonValue),
                getEndRangeTimestamp(selectedDateRangeButtonValue)
            )
        ];
        setComponentData(data);

        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // update chartData and barSeries when componentData, selectedDateRangeButtonValue, or selectedDoctorIds change
    useEffect(() => {
        if (!componentData) return;

        const { sentimentCategories, visitSentiment } = componentData;

        if (!sentimentCategories || !visitSentiment) return;

        // filter sentiment analysis by selectedDoctorIds
        const filteredBreakdown = visitSentiment.filter(visit => selectedDoctorIds.includes(visit.doctorId));

        // build sentiment category aggregates for both overall practice and selected doctors
        const practiceAggregate = buildSentimentCategoryAggregates(visitSentiment, sentimentCategories);
        const selectedAggregate = buildSentimentCategoryAggregates(filteredBreakdown, sentimentCategories);

        // set bar series
        const newBarSeries: BarSeriesType[] = [
            {
                data: selectedAggregate.map((d) => -1 * d.threeCount), // stays at zero
                id: SentimentRange.ThreeCount,
                label: '3',
                stack: 'divergingStack',
                color: getSentimentRangeColor(SentimentRange.ThreeCount),
                stackOffset: "diverging",
                type: 'bar',
            },
            {
                data: selectedAggregate.map((d) => -1 * d.twoCount), // push left
                id: SentimentRange.TwoCount,
                label: '2',
                stack: 'divergingStack',
                color: getSentimentRangeColor(SentimentRange.TwoCount),
                stackOffset: "diverging",
                type: 'bar',
            },
            {
                data: selectedAggregate.map((d) => -1 * d.oneCount), // push left
                id: SentimentRange.OneCount,
                label: '1',
                stack: 'divergingStack',
                color: getSentimentRangeColor(SentimentRange.OneCount),
                stackOffset: "diverging",
                type: 'bar',
            },
            {
                data: selectedAggregate.map((d) => d.fourCount), // push right
                id: SentimentRange.FourCount,
                label: '4',
                stack: 'divergingStack',
                color: getSentimentRangeColor(SentimentRange.FourCount),
                stackOffset: "diverging",
                type: 'bar',
            },
            {
                data: selectedAggregate.map((d) => d.fiveCount), // push right
                id: SentimentRange.FiveCount,
                label: '5',
                stack: 'divergingStack',
                color: getSentimentRangeColor(SentimentRange.FiveCount),
                stackOffset: "diverging",
                type: 'bar',
            },
        ];

        setBarSeries(newBarSeries);
        setChartData(selectedAggregate);
        setSentimentCategories(sentimentCategories);

        const newTotalAverage = getSentimentAverage(selectedAggregate);
        setSelectedAverage(newTotalAverage);

        const practiceAverage = getSentimentAverage(practiceAggregate);
        setPracticeAverage(practiceAverage);

    }, [selectedDateRangeButtonValue, componentData, selectedDoctorIds, dashboardDoctors]);

    // custom axis tooltip content
    const axisTooltip = (props: ChartsAxisContentProps) => {
        if (!props?.axisData || !chartData) return null;

        // snag the bar index from the axisData
        const barIndex = props?.axisData?.y?.index || 0;

        // snag the average from the chartData
        const avg = Math.round(chartData[barIndex].average * 10) / 10;
        let label = ""
        // set the label based on the x value
        if (typeof props?.axisData?.y?.value === 'string') {
            label = capitalizeFirstLetter(props?.axisData?.y?.value);
        }

        return (
            <div style={{
                background: "#0C111D",
                color: "#fff",
                padding: "5px 17px",
                border: "1px solid #0c111D",
                borderRadius: "4px",
                fontSize: "12px",
                fontFamily: "Plus Jakarta Sans",
                textAlign: "center",
                opacity: 0.9,
            }}>
                <div>{label}</div>
                <div>
                    <span style={{ marginRight: '2px' }}>Avg: </span>
                    <span>{avg.toString()}</span>
                </div>
            </div>
        );
    };

    const legendData: LegendData[] = [
        { color: "bg-[#CF2150]", label: "1" },
        { color: "bg-[#E894AB]", label: "2" },
        { color: "bg-[#D0D5DD]", label: "3" },
        { color: "bg-[#98EAEE]", label: "4" },
        { color: "bg-[#26B5BB]", label: "5" },
    ];

    // Legend Item
    const LegendItem: React.FC<LegendItemProps> = ({ color, label }) => {
        return (
            <div className="flex gap-2.5 items-center self-stretch my-auto">
                <div className={`flex shrink-0 self-stretch my-auto w-3.5 h-3.5 ${color} rounded`} />
                <div className="self-stretch my-auto">{label}</div>
            </div>
        );
    }

    // Legend
    const Legend: React.FC = () => {
        return (
            <div className="flex flex-wrap gap-10 justify-center items-center text-xs leading-snug whitespace-nowrap max-w-[552px] text-slate-600">
                {legendData.map((item, index) => (
                    <LegendItem
                        key={index}
                        color={item.color}
                        label={item.label}
                    />
                ))}
            </div>
        );
    }

    // if we click on the sentiment analysis component, navigate to the sentiment analysis tab
    const handleSentimentAnalysisClick = () => {
        handleTabClick(TabIds.SentimentAnalysis);
    }

    return (
        <div
            className="w-full min-h-[284px] bg-white rounded-md border border-gray-300"
            onClick={handleSentimentAnalysisClick}
        >
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[284px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="flex flex-col gap-4 px-4 py-6 ">
                    {/* HEADER */}
                    <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Sentiment Analysis</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-sentiment-analysis"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-sentiment-analysis']"
                                    content={
                                        "Visits are scored on a scale of 1 (low) to 5 (high) for each category."
                                    }
                                    place="top"
                                />
                            </div>

                        </div>
                        {/* Stat Cards */}
                        <div className="flex gap-3">
                            {showSelectedSentimentAverage && (
                                <StatDisplay
                                    value={selectedAverage}
                                    label={"AVG SCORE"}
                                    backgroundColor="bg-white"
                                    width="w-auto"
                                    labelAlignment={LabelAlignment.Row}
                                />
                            )}
                            <StatDisplay
                                value={practiceAverage}
                                label={"PRACTICE SCORE"}
                                backgroundColor="bg-max-50"
                                width="w-auto"
                                labelAlignment={LabelAlignment.Row}
                            />
                        </div>
                    </div>

                    <Divider customClassNames="rounded-lg" color="gray-100" />

                    {/* Chart */}
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <BarChart
                            layout="horizontal"
                            xAxis={[{
                                scaleType: 'linear',
                                disableLine: true,
                                disableTicks: true,
                                valueFormatter: () => '',
                            }]}
                            yAxis={[{
                                data: sentimentCategories,
                                scaleType: 'band',
                                disableTicks: true,
                                disableLine: true,
                                valueFormatter: (value) => capitalizeFirstLetter(value),
                            }]}
                            series={barSeries}
                            height={300}
                            margin={{ left: 80, right: 10, top: 20, bottom: 20 }}
                            grid={{ horizontal: true, vertical: true }}
                            borderRadius={8}
                            slotProps={{
                                legend: {
                                    hidden: true,
                                },
                            }}
                            tooltip={{
                                trigger: 'axis',
                                axisContent: (props) => axisTooltip(props),
                            }}
                            sx={{
                                //change left yAxis label styles
                                "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                    fill: "#475467"
                                },
                                // change bottom label styles
                                "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                    fill: "#475467",
                                    fontWeight: 500,
                                },
                                // change axis line styles
                                "& .MuiChartsAxis-line": {
                                    stroke: "#F2F4F7",
                                    borderRadius: "8px",
                                },
                                // change grid line styles
                                "& .MuiChartsGrid-line": {
                                    stroke: "#E0E0E0",
                                },
                                // change outside grid border radius
                                "& .MuiChartsGrid-root": {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                        <Legend />
                    </div>
                </div>
            )}
        </div>
    )
}