// third-party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// dashboard-specific components and utilities
import HDProportionalBars, { BarData } from "@features/dashboard/components/common/HDProportionalBars";
import { getStartRangeTimestamp, getEndRangeTimestamp } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, getLabelFromDoctorId, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";

// API and queries
import { makeServiceInsightsGraphqlRequest, VISITS_SEEN_QUERY } from "../dashboardAPI";
import { HDPercentageWheel } from "../common/HDPercentageWheel";

type VisitsSeenData = {
    totalVisitsSeen: number;
    totalVisitsScheduled: number;
    breakdownByDoctor: {
        [doctorId: string]: {
            visitsSeen: number,
            visitsScheduled: number
        }
    }
}

type VisitsSeenDataSet = {
    [key: string]: VisitsSeenData;
}

export async function hydrateVisitsSeen(
    bearerToken: string,
    dashboardDoctors: DashboardDoctor[],
    startRangeTimestamp: string,
    endRangeTimestamp: string
): Promise<Record<string, any>> {

    const variables = {
        startRangeTimestamp,
        endRangeTimestamp,
        selectedDoctorIDs: [...dashboardDoctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID], // retrieve data for all doctors in the dashboard, and unassigned
    }

    try {
        // make graphql request
        const { data, error } = await makeServiceInsightsGraphqlRequest(VISITS_SEEN_QUERY, variables, bearerToken);

        // handle error
        if (error) {
            throw error
        }

        // transform the result into your desired structure
        const breakdownByDoctor = data?.visitsSeen?.breakdownByDoctor.reduce(
            (acc: VisitsSeenData['breakdownByDoctor'], doctor: { doctorId: string; visitsSeen: number; visitsScheduled: number }) => {
                acc[doctor.doctorId] = {
                    visitsSeen: doctor.visitsSeen,
                    visitsScheduled: doctor.visitsScheduled,
                };
                return acc;
            },
            {}
        );

        // return the transformed data
        return {
            totalVisitsSeen: data?.visitsSeen?.totalVisitsSeen || 0,
            totalVisitsScheduled: data?.visitsSeen?.totalVisitsScheduled || 0,
            breakdownByDoctor,
        };
    } catch (error) {
        Sentry.captureException(`Error fetching visits seen: ${error}`);
        return {
            totalVisitsSeen: 0,
            totalVisitsScheduled: 0,
            breakdownByDoctor: {},
        }
    }
}

/*
* getVisitsSeenLabel returns the label for the number of visits seen by doctors in the practice.
*/
const getVisitsSeenLabel = (n: number) => {
    return n === 1 ? "1 Visit Seen" : `${n} Visits Seen`;
}

/*
* VisitsSeen displays the number of visits seen by doctors in the practice, compared with the number 
* of visits that were scheduled.
*/
export const VisitsSeen = () => {

    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const metricData = dashboardMetricsContext?.metricData[MetricKeys.VisitsSeen]?.data as VisitsSeenDataSet;

    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors } = dashboardStateContext;

    // state for managing what is currently displayed in the graph
    const [componentData, setComponentData] = useState<VisitsSeenData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [barData, setBarData] = useState<BarData[]>([]);
    const [remainderData, setRemainderData] = useState<BarData | undefined>(undefined);

    // state to track if all doctors are selected
    const [allDocsSelected, setAllDocsSelected] = useState<boolean>(false);

    // update allDocsSelected when selectedDoctorIds change
    useEffect(() => {
        setAllDocsSelected(selectedDoctorIds.length === dashboardDoctors.length);
    }, [selectedDoctorIds, dashboardDoctors]);


    // update componentData when metricData or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[getDataKey(dashboardDoctors, getStartRangeTimestamp(selectedDateRangeButtonValue), getEndRangeTimestamp(selectedDateRangeButtonValue))];
        setComponentData(data);
        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // update displayed data when componentData changes
    useEffect(() => {
        // if all doctors are selected, display all doctors in the graph
        if (selectedDoctorIds.length === dashboardDoctors.length) {
            // display all doctors
            const barData = Object.keys(componentData?.breakdownByDoctor || {}).map((doctorId) => {
                // get the data for the doctor
                const doctorData = componentData?.breakdownByDoctor[doctorId];

                // return the data in the format for HDProportionalBars
                return {
                    numeric: doctorData?.visitsSeen || 0,
                    label: getLabelFromDoctorId(doctorId, dashboardDoctors),
                    detail: getVisitsSeenLabel(doctorData?.visitsSeen || 0)
                }
            });

            // set the bar data
            setBarData(barData);

            // compute remainder (from deleted doctors) as difference between total visits seen and sum of displayed doctors
            const totalBarCount = Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0);
            const totalPracticeCount = componentData?.totalVisitsSeen || 0;
            const remainderCount = totalPracticeCount ? totalPracticeCount - totalBarCount : 0
            const remainderData = {
                numeric: remainderCount,
                detail: getVisitsSeenLabel(remainderCount),
                label: "Other Doctors",
                hideLabel: true
            }

            // set the remainder data
            setRemainderData(remainderData);

        } else {
            // otherwise, display only the selected doctors
            const barData = selectedDoctorIds.map((doctorId) => {
                // get the data for the doctor
                const doctorData = componentData?.breakdownByDoctor[doctorId];

                // return the data in the format for HDProportionalBars
                return {
                    numeric: doctorData?.visitsSeen || 0,
                    label: getLabelFromDoctorId(doctorId, dashboardDoctors),
                    detail: getVisitsSeenLabel(doctorData?.visitsSeen || 0)
                }
            });

            // set the bar data
            setBarData(barData);

            // compute remainder as difference between total visits seen and sum of displayed doctors
            const totalSelectedCount = Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0);
            const totalPracticeCount = componentData?.totalVisitsSeen || 0;
            const remainderCount = totalPracticeCount ? totalPracticeCount - totalSelectedCount : 0
            const remainderData = {
                numeric: remainderCount,
                detail: getVisitsSeenLabel(remainderCount),
                label: "Other Doctors",
                hideLabel: true
            }

            // set the remainder data
            setRemainderData(remainderData);

        }
    }, [componentData, selectedDoctorIds, dashboardDoctors]);

    // state to track selected number of visits seen and scheduled
    const [selectedVisitsSeen, setSelectedVisitsSeen] = useState<number>(0);
    const [selectedVisitsScheduled, setSelectedVisitsScheduled] = useState<number>(0);

    // update selectedVisitsSeen and selectedVisitsScheduled when componentData changes
    useEffect(() => {
        if (!componentData) return;

        // if all doctors are selected, display all doctors in the graph
        if (selectedDoctorIds.length === dashboardDoctors.length) {
            setSelectedVisitsSeen(componentData.totalVisitsSeen);
            setSelectedVisitsScheduled(componentData.totalVisitsScheduled);
        } else {
            // otherwise, display only the selected doctors
            const selectedVisitsSeen = selectedDoctorIds.reduce((sum, doctorId) => sum + (componentData?.breakdownByDoctor[doctorId]?.visitsSeen || 0), 0);
            const selectedVisitsScheduled = selectedDoctorIds.reduce((sum, doctorId) => sum + (componentData?.breakdownByDoctor[doctorId]?.visitsScheduled || 0), 0);

            setSelectedVisitsSeen(selectedVisitsSeen);
            setSelectedVisitsScheduled(selectedVisitsScheduled);
        }
    }, [componentData, selectedDoctorIds, dashboardDoctors]);

    /*
    * safePercentage calculates a percentage, but returns 0 if the denominator is 0 or if either value is undefined.
    */
    const safePercentage = (x1: number | undefined, x2: number | undefined) => {
        if (x2 === 0) {
            return 0;
        }

        if (x1 === undefined || x2 === undefined) {
            return 0;
        }

        return (x1 / x2) * 100;

    }

    return (
        <div className="w-full h-auto min-h-[284px] bg-white rounded-md border border-gray-300">
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[284px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="px-4">
                    {/* HEADER */}
                    <div className="flex items-center py-4 justify-between">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Visits Seen</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-visits-seen"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-visits-seen']"
                                    content={
                                        "A count of visits seen with HappyDoc compared to the count of total scheduled visits."
                                    }
                                    place="top"
                                />
                            </div>

                        </div>
                        <StatDisplay
                            value={componentData?.totalVisitsSeen?.toString()}
                            label={allDocsSelected ? "TOTAL" : "PRACTICE TOTAL"}
                            backgroundColor="bg-max-50"
                            width="w-auto"
                            labelAlignment={LabelAlignment.Row}
                        />
                    </div>
                    <Divider customClassNames="rounded-lg" color="gray-100" />
                    {/* TOTAL FOR SELECTED */}
                    <div className="flex flex-row items-center py-5">
                        <div className="w-1/2 flex flex-row items-end gap-3 py-0">
                            <div className=" text-5xl font-semibold font-['Fraunces']">{Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0)}</div>
                            <div className="text-sm text-gray-600 pb-1">
                                Total Visits Seen<br />with HappyDoc
                            </div>
                        </div>
                        <Divider customClassNames="h-[60px] w-[2px] bg-gray-200 mx-5" />
                        <div className="w-1/2 flex flex-row gap-3 py-0 items-center">
                            <div data-tooltip-id="tooltip-hd-percentage-wheel">
                                <HDPercentageWheel
                                    percentage={safePercentage(selectedVisitsSeen, selectedVisitsScheduled)}
                                    height={60}
                                    width={60}
                                />
                            </div>
                            <div className="text-sm text-gray-600 pb-1">
                                Of {selectedVisitsScheduled} Scheduled<br />Practice Visits
                            </div>

                            <Tooltip
                                style={{
                                    maxWidth: "200px",
                                    zIndex: 9999,
                                    fontSize: "12px",
                                    fontFamily: "Plus Jakarta Sans",
                                    textAlign: "center",
                                }}
                                anchorSelect="[data-tooltip-id='tooltip-hd-percentage-wheel']"
                                place="top"
                                children={
                                    <div>
                                        Seen: {selectedVisitsSeen}
                                        <br />
                                        Scheduled: {selectedVisitsScheduled}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    {/* BREAKDOWN BY DOCTOR */}
                    <HDProportionalBars data={barData} remainderData={remainderData} name={MetricKeys.VisitsSeen} />
                </div>
            )}
        </div>
    );
}
