import React from 'react';
import { Tooltip } from 'react-tooltip';
import { getModularColor } from "@features/dashboard/components/common/color";

export type BarData = {
    numeric: number;
    label?: string;
    detail?: string;
    hideLabel?: boolean;
}

type ProportionalBarData = BarData & {
    proportion: number;
}

export type ProportionalBarsProps = {
    data: BarData[];
    remainderData?: BarData;
    hideNumeric?: boolean;
    hideLabel?: boolean;
    // only used for tooltip ids so that they are unique
    // otherwise, the tooltip will show the same content for all bars
    name: string;
}

const renderProportionalBar = (bar: ProportionalBarData, index: number, name: string, remainder: boolean) => {
    // don't render bars of zero length
    if (bar.numeric === 0) {
        return null;
    }

    const largeEnoughToRenderNumericLabel = bar.proportion > 7 || (bar.proportion > 4 && bar.numeric < 10);
    const hideNumeric = !largeEnoughToRenderNumericLabel;

    const largeEnoughToRenderLabel = bar.proportion > 6;
    const hideLabel = !largeEnoughToRenderLabel || bar.hideLabel;

    const color = remainder ? "gray-200" : getModularColor(index);

    return (
        <div
            className="flex flex-col w-full pb-4"
            style={{ width: `${bar.proportion.toFixed(2)}%` }}
            key={`bar-${index}`}
        >
            {!hideNumeric ? (
                <div className="text-2xl font-semibold font-['Fraunces'] leading-loose">
                    {bar.numeric}
                </div>
            ) : (
                <div className="h-12" />
            )}
            <div
                id={`bar-tooltip-${name}-${index}`}
                className={`h-5 bg-${color} rounded-md`}
                style={{ position: 'relative' }}
            >
                <Tooltip
                    style={{
                        maxWidth: "200px",
                        zIndex: 9999,
                        fontSize: "12px",
                        fontFamily: "Plus Jakarta Sans",
                        textAlign: "center",
                    }}
                    anchorSelect={`#bar-tooltip-${name}-${index}`}
                    render={() => (
                        <span>
                            {bar.label}<br />{bar.detail}
                        </span>
                    )}
                    place="top"
                />
            </div>
            {!hideLabel ? (
                <div className="text-[10px] text-gray-600 font-normal leading-[14px] truncate overflow-hidden whitespace-nowrap">
                    {bar.label}
                </div>
            ) : (
                <div className="h-3.5 pb-3.5" />
            )}
        </div>
    );
};

const HDProportionalBars = ({ data, remainderData, name }: ProportionalBarsProps) => {
    const totalNumeric = React.useMemo(() => {
        return data.reduce((sum, item) => sum + item.numeric, 0) + (remainderData ? remainderData.numeric : 0);
    }, [data, remainderData]);

    const proportionalData = React.useMemo(() => {
        return data
            .map((item) => ({
                ...item,
                proportion: totalNumeric > 0 ? (item.numeric / totalNumeric) * 100 : 0,
            }))
            .sort((a, b) => {
                if (b.numeric !== a.numeric) {
                    return b.numeric - a.numeric;
                }
                return (a.label || '').localeCompare(b.label || '');
            });
    }, [data, totalNumeric]);

    const proportionalRemainderData = React.useMemo(() => {
        if (!remainderData) return undefined;
        return {
            ...remainderData,
            proportion: totalNumeric > 0 ? (remainderData.numeric / totalNumeric) * 100 : 0,
        };
    }, [remainderData, totalNumeric]);

    return (
        <div className="flex items-center gap-1 w-full">
            {proportionalData.map((bar, idx) => renderProportionalBar(bar, idx, name, false))}
            {proportionalRemainderData && renderProportionalBar(proportionalRemainderData, data.length, name, true)}
        </div>
    );
};

// memoize the component
export default React.memo(HDProportionalBars);
