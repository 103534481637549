import Spinner from '@/common/components/Spinner';

const Loading: React.FC = () => {
    return (
        <div className="w-full flex flex-row items-center justify-center gap-2">
            <Spinner />
            <span className='text-gray-900 font-semibold'>Loading Data...</span>
        </div>
    );
};

export default Loading;