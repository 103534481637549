import classNames from "classnames";

export enum LabelAlignment {
    Row = "row",
    Column = "column"
}

interface StatDisplayProps {
    value: string;
    label: string;
    backgroundColor?: string;
    width?: string;
    labelAlignment?: LabelAlignment;
}

const StatDisplay: React.FC<StatDisplayProps> = ({
    value,
    label,
    backgroundColor = "bg-red-50",
    width = "w-full",
    labelAlignment = LabelAlignment.Column
}) => {
    const isRowAlignment = labelAlignment === LabelAlignment.Row;
    return (
        <div
            className={classNames(
                `flex flex-col justify-center px-4 py-2 ${backgroundColor} rounded-md max-w-full ${width}`,
                backgroundColor === "bg-white" ? "border-[1px] border-max-100" : ""
            )}
        >
            <div
                className={`flex ${isRowAlignment
                    ? "flex-row items-end gap-2"
                    : "flex-col items-start"
                    } w-full`}
            >
                <div
                    className="text-2xl font-semibold leading-8 text-gray-900"
                    style={{ fontFamily: 'Fraunces, sans-serif' }}
                >
                    {value}
                </div>
                <div className={`text-xs leading-4 text-slate-600 ${isRowAlignment ? "pb-1" : ""}`}>
                    {label}
                </div>
            </div>
        </div>
    );
};

export default StatDisplay;